import { defineStore } from 'pinia'


import {
	reactive
} from 'vue'

import {
	getFullPath
} from '@/core/utils/app'



const useUserStore = defineStore('UserStore', {
	state: () => {
		return {
			hyToken: '',//登录令牌
			hyAdminToken: '',//管理令牌
			fullPath: '',//登录成功返回页面路径  
			lastTimestamp: 0,//初始60s时时间戳
			voteSlideTip: false,//是否投票记录左右滑动提示
			userInfo: "",//用户信息
		}
	},
	actions: {
		setFullPath(fullPath : string) {
			this.fullPath = encodeURIComponent(fullPath);
		},
		//登录令牌失效
		loginFailure() {
			this.logout();
			// #ifdef MP-WEIXIN  || MP-TOUTIAO
			uni.reLaunch({
				url: '/pages/login/authLogin'
			})
			// #endif 
			// #ifndef MP-WEIXIN  || MP-TOUTIAO
			uni.reLaunch({
				url: '/pages/login/pwdLogin'
			})
			// #endif  
		},
		//退出登录
		logout() {
			this.fullPath = encodeURIComponent(getFullPath())
			this.userInfo = "";
			this.hyToken = "";
			// #ifdef MP-WEIXIN  || MP-TOUTIAO
			uni.reLaunch({
				url: '/pages/login/authLogin'
			})
			// #endif 
			// #ifndef MP-WEIXIN  || MP-TOUTIAO
			uni.reLaunch({
				url: '/pages/login/pwdLogin'
			})
			// #endif  
		},//退出管理
		manageLogout() {
			this.hyAdminToken = "";
			uni.reLaunch({
				url: '/pages/tabbar/my/my'
			})
		},
		//登录成功
		loginSuccee(loginData : any) {
			this.hyToken = loginData.token;
			this.userInfo = loginData.userInfo;
			setTimeout(() => {
				let fullPath = this.fullPath;
				if (fullPath) {
					fullPath = decodeURIComponent(fullPath);
				}
				if (fullPath && fullPath.indexOf('/pages/tabbar/') != -1) {//包含tabbar
					uni.switchTab({ url: fullPath })
					return;
				}
				if (!fullPath || (fullPath && fullPath.indexOf('/pages/login/') != -1)) {//包含login 或 不存在返回路径
					uni.switchTab({ url: '/pages/tabbar/my/my' })
					return;
				}
				uni.redirectTo({ url: fullPath })
			}, 1400)
		}
	},
	getters: {

	},
	persist: {
		key: 'user',
		storage: {
			getItem(key) {
				return uni.getStorageSync(key)
			},
			setItem(key, value) {
				return uni.setStorageSync(key, value)
			},
		}, 
	}, 
})

export default useUserStore