export default {
	//商品列表
	appGoodsList: '/hyApi/appDevelop/goods/list',
	//商品详情
	appGoodsDetails: "/hyApi/appDevelop/goods/details/",
	//创建订单
	createOrder: "/hyApi/appDevelop/goods/createOrder",
	//订单列表
	orderList: "/hyApi/appDevelop/order/list",
	//订单详情
	orderDetails: "/hyApi/appDevelop/order/details",
	//支付结果查询
	paymentResults: "/hyApi/appDevelop/paymentResults",
	//再次拉起支付
	pullPay: "/hyApi/appDevelop/pullPay",
	//关闭订单
	closeOrder: "/hyApi/appDevelop/close/payOrder",
	//申请退款
	refundApply: "/hyApi/appDevelop/apply/refund",
	//退款详情
	refundDetails: "/hyApi/appDevelop/order/refund/details",
	//关闭售后
	closeRefundOrder: "/hyApi/appDevelop/closeRefundOrder",
	//关闭售后
	eachStateNum: "/hyApi/appDevelop/eachStateNum",
	
	
}