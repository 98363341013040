export default {
	orderAPP: {
		orderStatus: [{
			name: '已取消',
			state: 0
		}, {
			name: '已完成',
			state: 1
		}, {
			name: '待付款',
			state: 2
		}, {
			name: '待接单',
			state: 3
		}, {
			name: '开发中',
			state: 4
		}],//售后状态
		refundStatus: [{
			name: '已取消',
			state: 0
		}, {
			name: '待平台处理',
			state: 1
		}, {
			name: '退款中',
			state: 2
		}, {
			name: '退款完成',
			state: 3
		}, {
			name: '平台拒绝退款',
			state: 4
		}, {
			name: '退款关闭',
			state: 5
		}, {
			name: '退款异常，请联系客服',
			state: 6
		}],//订单已取消原因
		cancelReason: [{
			name: "买家取消订单",
			state: 1
		}, {
			name: "平台取消订单",
			state: 2
		}, {
			name: "超时未支付",
			state: 3
		}, {
			name: "买家申请退款",
			state: 4
		}, {
			name: "买家支付失败",
			state: 5
		}, {
			name: "平台拒绝接单",
			state: 6
		}]
	},
	writingType: [
		{ value: 1, label: '人生' },
		{ value: 2, label: '伤感' },
		{ value: 3, label: '励志' },
		{ value: 4, label: '情感' },
		{ value: 5, label: '名言' },
		{ value: 6, label: '其他' },
	]

};