//订单状态
export const appOrderStatus = (data : any, status : number) : string => {
	return data.find((item : any) => {
		return item.state == status;
	}).name
}
//订单售后状态
export const appOrderRefundStatus = (data : any, status : number) : string => {
	return data.find((item : any) => {
		return item.state == status;
	}).name
}
//订单取消原因
export const appOrderCancelReason = (data : any, status : number) : string => {
	return data.find((item : any) => {  
		return item.state == status;
	}).name
}