export default {
	//列表
	userList: "/howeApi/user/userList",
	//用户详情
	userDetails: "/howeApi/user/user/details",
	//用户修改
	userUpdate: "/howeApi/user/user/update",
	//注册量
	registerNum:"/howeApi/user/user/registerNum",
	//用户数据统计
	userStatistics:"/howeApi/user/user/statistics",

}