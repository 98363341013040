import { defineStore } from 'pinia'

const usePopupStore = defineStore('PopupStore', {
	state: () => {
		return {
			hyToast: {
				show: false,
				type: 'success',
				text: '',
				size: 32,
				duration: 2200
			}
		}
	},
	actions: {
		//显示toast
		async showToast(toast : any) {
			//先隐藏之前的
			toast.show = false;
			this.hyToast = toast;
			//显示
			toast = await this.toastHandle(toast)
			toast.show = true;
			this.hyToast = toast;
			//定时隐藏 
			let timeout = setTimeout(async () => {
				this.hyToast.show = false;
			}, toast.duration);
		},
		async hideToast(context : any) {
			let toast = {}
			toast = await this.toastHandle(toast)
			toast.show = false;
			toast = await this.toastHandle(toast)
		},
		toastHandle(toast : any) {
			return {
				show: (toast && toast.show) || false,
				type: (toast && toast.type) || 'success',
				text: (toast && toast.text) || '',
				size: (toast && toast.size) || 32,
				duration: (toast && toast.duration) || 2200
			}
		}
	},
	getters: {
		getHyToast: (state : any) => state.hyToast
	},
	// persist: {
	// 	key: 'hyToast',
	// 	storage: {
	// 		getItem(key) {
	// 			return uni.getStorageSync(key)
	// 		},
	// 		setItem(key, value) {
	// 			return uni.setStorageSync(key, value)
	// 		},
	// 	}, 
	// }, 
})

export default usePopupStore