//页面主题皮肤定义          https://materialui.co/colors/
interface Person {
	[propName : string] : {
		title : String,
		colors : {
			hyBaseBg : String,
			hyBaseColor : String,
			hyPosterBg : String,
			hySubColor : String,
			hyOtherColor : String,
			hyDeepColor : String,
			hyBaseDisabled : String,
			hyLineBgColor : String, //input   border颜色
			hyLightGray : String,//灰色
		}
	}
}
const theme : Person = {
	//------------------------皮肤主题------------------------
	red: {
		title: '红色',
		colors: {
			hyBaseBg: 'linear-gradient(267deg, #E57373 0%, #F44336 100%)', //300  500
			hyPosterBg: 'linear-gradient(0deg, #FF1744 0%, #C51162 100%)', //A400  A700
			hyBaseColor: '#E53935', //600 
			hyBaseDisabled: '#E57373', //300
			hySubColor: '#FF1744', //a400
			hyOtherColor: '#FF8A80', //a100
			hyDeepColor: '#B71C1C', //900
			hyLineBgColor: '#F7F7F7',
			hyLightGray: '#9a9a9a',
		}
	},
	yellow: {
		title: '黄色',
		colors: {
			hyBaseBg: 'linear-gradient(267deg, #FFD54F 0%, #FFC107 100%)', //300  500
			hyPosterBg: 'linear-gradient(0deg, #FFC400 0%, #FFD600 100%)',  //A400  A700
			hyBaseColor: '#FFB300', //600
			hyBaseDisabled: '#FFD54F', //300
			hySubColor: '#FFC400', //a400
			hyOtherColor: '#FFE57F', //a100
			hyDeepColor: '#FF6F00', //900
			hyLineBgColor: '#F7F7F7',
			hyLightGray: '#9a9a9a',
		}
	},
	green: {
		title: '绿色',
		colors: {
			hyBaseBg: 'linear-gradient(267deg, #81C784 0%, #4CAF50 100%)', //300  500
			hyPosterBg: 'linear-gradient(0deg, #00E676 0%, #64DD17 100%)', //A400  A700
			hyBaseColor: '#43A047', //600
			hyBaseDisabled: '#81C784', //300
			hySubColor: '#00E676', //a400
			hyOtherColor: '#00E676', //a100
			hyDeepColor: '#1B5E20', //900
			hyLineBgColor: '#F7F7F7',
			hyLightGray: '#9a9a9a',
		}
	},
	blue: {
		title: '蓝色',
		colors: {
			hyBaseBg: 'linear-gradient(267deg, #64B5F6 0%, #2196F3 100%)', //300  500
			hyPosterBg: 'linear-gradient(0deg, #2979FF 0%, #304FFE 100%)', //A400  A700
			hyBaseColor: '#039BE5', //600
			hyBaseDisabled: '#4FC3F7', //300
			hySubColor: '#2979FF', //a400
			hyOtherColor: '#82B1FF', //a100
			hyDeepColor: '#0D47A1', //900
			hyLineBgColor: '#F7F7F7',
			hyLightGray: '#9a9a9a',
		}
	},
	purple: {
		title: '紫色',
		colors: {
			hyBaseBg: 'linear-gradient(267deg, #BA68C8 0%, #BA68C8 100%)', //300  500
			hyPosterBg: 'linear-gradient(0deg, #D500F9 0%, #6200EA 100%)', //A400  A700
			hyBaseColor: '#8E24AA', //600
			hyBaseDisabled: '#BA68C8', //300
			hySubColor: '#D500F9', //a400
			hyOtherColor: '#EA80FC', //a100
			hyDeepColor: '#4A148C', //900
			hyLineBgColor: '#F7F7F7',
			hyLightGray: '#9a9a9a',
		}
	},
}
export default theme;