//ts  vite方式导入
//const components: any = import.meta.glob('../components/**/*.vue')
const utils : any = import.meta.glob('./utils/*.ts', { eager: true })
const config : any = import.meta.glob('./config/*.ts', { eager: true })
const common : any = import.meta.glob('./common/*.ts', { eager: true })
const api : any = import.meta.glob('./api/*.ts', { eager: true })

// 引入 
import mitt from 'mitt'
const Mitt = mitt()


const install = (app : any) => {

	//挂组件
	// Object.keys(components).forEach(key => {
	// 	let component = components[key].default
	// 	app.component(component.name, component)
	// })
	//自定义指令
	app.directive('auth', {//判断权限显示隐藏 
		mounted(el : any, binding : any) {
 
		},
		created() {

		}
	})


	// 批量挂载插件 
	for (let fileName in utils) {
		const pluginName = '$' + fileName.replace(/(.*\/)*([^.]+).*/ig, "$2");
		const pluginConfig = utils[fileName]
		app.config.globalProperties[pluginName] = pluginConfig;
	}
	for (let fileName in config) {
		const pluginName = '$' + fileName.replace(/(.*\/)*([^.]+).*/ig, "$2");
		const pluginConfig = config[fileName].default;
		app.config.globalProperties[pluginName] = pluginConfig;
	}
	for (let fileName in common) {
		const pluginName = '$' + fileName.replace(/(.*\/)*([^.]+).*/ig, "$2");
		const pluginConfig = common[fileName].default
		app.config.globalProperties[pluginName] = pluginConfig;
	}
	for (let fileName in api) {
		const pluginName = '$' + fileName.replace(/(.*\/)*([^.]+).*/ig, "$2");
		const pluginConfig = api[fileName].default;
		app.config.globalProperties[pluginName] = pluginConfig;
	}
	app.config.globalProperties.$Bus = Mitt;

	//先加载启动  onLaunched  在加载 onLoad
	app.config.globalProperties.$onLaunched = new Promise(resolve => {
		app.config.globalProperties.$isResolve = resolve;
	})

}
// 导出
export default install