import appConfig from './appConfig';

let assetsPath;
let avatarBase;

// （海报图片防止跨域）
// #ifdef H5
assetsPath = appConfig.assetsBase;
avatarBase = appConfig.avatarBase;
// #endif
// #ifndef H5
assetsPath = appConfig.domainName + appConfig.assetsBase;
avatarBase = appConfig.domainName + appConfig.avatarBase;
// #endif


// assetsPath = appConfig.domainName + appConfig.assetsBase;
// avatarBase = appConfig.domainName + appConfig.avatarBase;


//=====本地图片只有static 》Howe》static 有用
let staticImg : any = {
	avatarBase: avatarBase,
	logo: assetsPath + 'logo.png',
	logoEmpower: assetsPath + 'logoEmpower.jpg',
	businessLicense: assetsPath + 'businessLicense.jpg',
	domainCertificate: assetsPath + 'domainCertificate.jpg',
	filingInfo: assetsPath + 'filingInfo.png',

	qrcode: assetsPath + 'qrcode.png',
	appPoster: assetsPath + 'appPoster/appPoster.png',
	dailyPoster: assetsPath + 'dailyPoster/dailyPoster.png',
	//文案类型
	writingAll: avatarBase + 'writing-all.png',
	writingHappy: avatarBase + 'writing-happy.png',
	writingSentimental: avatarBase + 'writing-sentimental.png',
	writingCure: avatarBase + 'writing-cure.png',
	writingEmotion: avatarBase + 'writing-emotion.png',
	writingOther: avatarBase + 'writing-other.png',
	writingExciting: avatarBase + 'writing-exciting.png',

	//商品图片
	goods: assetsPath + 'goods/',

	assetsPath: assetsPath,


}


export default staticImg