export default {
	//微信授权登录
	wxAuthLogin: '/hyApi/login/login/wechat/authorizedLogin',
	//抖音授权登录
	dyAuthLogin: '/hyApi/login/login/dy/authorizedLogin',
	//发送修改密码验证码
	sendPassCode: '/hyApi/send/updatePassword/code',
	//发送登录验证码
	sendLoginCode: '/hyApi/send/login/code',
	//验证码登录
	codeLogin: '/hyApi/login/login/codeLogin',
	//密码登录
	passwordLogin: '/hyApi/login/login/passwordLogin',
	//用户信息
	getUserInfo: '/hyApi/user/userInfo',
	//意见反馈-列表
	feedbackList: '/hyApi/user/feedback/list',
	//意见反馈-创建
	feedbackAdd: '/hyApi/user/feedback/add',
	//修改用户信息
	upateUserInfo: "/hyApi/user/userInfo/update",
	//修改密码
	updatePassword: "/hyApi/user/updatePassword",
	//注销
	userLogout: "/hyApi/user/logout",

}