export default {
	//意见反馈-列表
	feedbackList: "/howeApi/system/feedback/list",
	//意见反馈-修改状态
	feedbackStatus: '/howeApi/system/feedback/status',
	//联系方式
	contactUs: '/howeApi/system/contactUs',
	//修改联系方式
	contactUsUpdate: '/howeApi/system/contactUs/update',
	//公告列表
	noticeList: "/howeApi/system/notice/list",
	//编辑公告
	noticeEdit: "/howeApi/system/notice/edit",
	//公告详情
	noticeDetails: "/howeApi/system/notice/details",
	//加载动画
	loadAnimation: "/howeApi/system/loadAnimation/index",
	//修改加载动画
	loadAnimationUpdate: "/howeApi/system/loadAnimation/update",
	//加载动画
	skin: "/howeApi/system/skin/index",
	//修改加载动画
	skinUpdate: "/howeApi/system/skin/update",
	//tabbar
	tabbarIndex: "/howeApi/system/tabbar/index",
	//tabbar
	tabbarUpdate: "/howeApi/system/tabbar/update",
	//系统维护
	maintenance: "/howeApi/system/maintenance/index",
	//系统维护保存
	updateMaintenance: "/howeApi/system/maintenance/update",
	//编辑app更新
	appUpdateEdit: "/howeApi/system/appUpdate/edit",
	//更新列表
	appUpdateList: "/howeApi/system/appUpdate/list",
	//更新列表删除
	appUpdateDel: "/howeApi/system/appUpdate/del",
	//权限列表
	limitsList:"/howeApi/system/limits/list",
	//权限删除
	limitsItemDel:"/howeApi/system/limits/del",
	//编辑
	limitsEdit:"/howeApi/system/limits/edit",
	//修改状态
	limitsUpdateStatus:"/howeApi/system/limits/updateStatus",
	//登录日志
	logUserLogin:"/howeApi/system/log/login",
	//操作日志
	logOperate:"/howeApi/system/log/operate",
	//短信日志
	logSMS:"/howeApi/system/log/sms",
	
	
	
}