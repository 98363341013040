const tabbar : ReadonlyArray<object> = [{
	text: '软件开发',
	iconName: 'front-end',
	path: "/pages/tabbar/develop/develop",
	color: '#999',
	selColor: '#D42A24',
	id: 1
}, {
	text: '文案',
	iconName: 'writing',
	color: '#999',
	path: "/pages/tabbar/writing/writing",
	selColor: '#D42A24',
	id: 2
}, {
	text: '去水印',
	iconName: 'video-parsing',
	path: "/pages/tabbar/analysis/analysis",
	color: '#999',
	selColor: '#D42A24',
	id: 3
}, {
	text: '日常服务',
	iconName: 'serve',
	path: "/pages/tabbar/serve/serve",
	color: '#999',
	selColor: '#D42A24',
	id: 4
}, {
	text: '我的',
	iconName: 'my',
	color: '#999',
	selColor: '#D42A24',
	path: "/pages/tabbar/my/my",
	id: 5
}];

export default tabbar;