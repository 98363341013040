import $appConfig from '@/core/config/appConfig';

/**
  * @Description:
  * @author:Howe
  * @param  校验是否是图片地址
  * @return
  * @createTime: 2023-02-05 17:31:20
  * @Copyright by 红逸软件开发工作室
  */

export const checkImgExists = (imgurl : String) => {
	if (!/\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(imgurl)) {
		return false;
	} else {
		return true;
	}
}

/**
  * @Description:  手机号验证
  * @author:Howe
  * @param phone  手机号
  * @return
  * @createTime: 2023-09-30 23:20:59
  * @Copyright by 红逸软件开发工作室
  */

export const isPhone = (phone:string) => {
	if (!/^1(3|4|5|6|7|8)\d{9}$/.test(phone)) {
		return false;
	} else {
		return true;
	}
}
