import $appConfig from '@/core/config/appConfig';
import $adminRouteConfig from '@/core/config/adminRouteConfig';
/**
 * @Description: 路由拦截器
 * @author:Howe
 * @param
 * @return
 * @createTime: 2022-12-28 14:54:27
 * @Copyright by 红逸软件开发工作室
 */

const JumpList = ['navigateTo', 'redirectTo', 'reLaunch', 'switchTab'];
const tokenWhiteList = $appConfig.tokenWhiteList;

import useUserStore from '@/store/modules/user';

JumpList.forEach((jump) => {
	uni.addInterceptor(jump, {
		invoke(e) {
			//拦截前
			const userStore = useUserStore();
			if (!e.url) return false;
			const url = e.url.split('?')[0];
			//token  拦截
			let find = tokenWhiteList.find((item: any) => {
				//查找当前路由是否在白名单
				return url.includes(item.replace(/(\*)|(\**)/g, ''));
			});

			if (!find) {
				//需要token的路由
				userStore.setFullPath(e.url);

				let loginUrl = '/pages/login/pwdLogin';
				// #ifdef  MP-WEIXIN || MP-TOUTIAO
				loginUrl = '/pages/login/authLogin';
				// #endif

				const hyToken = userStore.hyToken;
				const hyAdminToken = userStore.hyAdminToken;

				if (!hyToken) {
					//没有token 跳转登录
					uni.navigateTo({
						url: loginUrl
					});
					return false;
				}

				find = $adminRouteConfig.find((item: any) => {
					//管理员查找当前路由是否在白名单
					if (item.path && item.verify) {
						return url.includes(item.path.replace(/(\*)|(\**)/g, ''));
					}
				});

				if (find && !hyAdminToken) {
					uni.switchTab({
						url: '/pages/tabbar/my/my'
					});
					return false;
				}
			}
		},
		success(e) {}
	});
});
