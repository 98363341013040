//自己封装的处理项目的方法
/**
  * @Description:提取文本里面的网址
  * @author:Howe
  * @param
  * @return
  * @createTime: 2023-03-03 00:27:57
  * @Copyright by 红逸软件开发工作室
  */
export const extractURL = (str : string | null) => {
	if (!str) return "";
	var reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;
	str = str.match(reg);
	return (str)
}

/**
  * @Description:当前时间戳
  * @author:Howe
  * @param
  * @return
  * @createTime: 2023-03-03 00:27:57
  * @Copyright by 红逸软件开发工作室
  */

export const getCurrentTimestamp = () => {
	return Math.round(new Date().getTime() / 1000);
}

/**
  * @Description:获取当前时间
  * @author:Howe
  * @param
  * @return
  * @createTime: 2023-03-03 00:27:57
  * @Copyright by 红逸软件开发工作室
  */
export const getCurrentDateTime = (type : number) => {
	var date = new Date();
	var sign2 = ":";
	var year : string | number = date.getFullYear() // 年
	var month : string | number = date.getMonth() + 1; // 月
	var day : string | number = date.getDate(); // 日
	var hour : string | number = date.getHours(); // 时
	var minutes : string | number = date.getMinutes(); // 分
	var seconds : string | number = date.getSeconds() //秒
	var weekArr = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期天'];
	var week = weekArr[date.getDay()];
	if (month >= 1 && month <= 9) {
		month = "0" + month;
	}
	if (day >= 0 && day <= 9) {
		day = "0" + day;
	}
	if (hour >= 0 && hour <= 9) {
		hour = "0" + hour;
	}
	if (minutes >= 0 && minutes <= 9) {
		minutes = "0" + minutes;
	}
	if (seconds >= 0 && seconds <= 9) {
		seconds = "0" + seconds;
	}
	switch (type) {
		case 1:
			return year;
			break;
		case 2:
			return year + "-" + month;
			break;
		case 3:
			return year + "-" + month + "-" + day;
			break;
		case 4:
			return year + "-" + month + "-" + day + " " + hour;
			break;
		case 5:
			return year + "-" + month + "-" + day + " " + hour + sign2 + minutes;
			break;
		case 6:
			return year + "-" + month + "-" + day + " " + hour + sign2 + minutes + sign2 + seconds;
			break;
		default:
			return year + "-" + month + "-" + day + " " + hour + sign2 + minutes + sign2 + seconds;
	}
}

/**
  * @Description: 十六进制转rgba
  * @author:Howe
  * @param  str  十六进制（6位）
  * @param  opacity<number>  透明度
  * @return
  * @createTime: 2023-05-31 17:32:30
  * @Copyright by 红逸软件开发工作室
  */
export const set16ToRgb = (str : string, opacity : number = 1) => {
	var reg = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/
	if (!reg.test(str)) { return; }
	let newStr = (str.toLowerCase()).replace(/\#/g, '')
	let len = newStr.length;
	if (len == 3) {
		let t = ''
		for (var i = 0; i < len; i++) {
			t += newStr.slice(i, i + 1).concat(newStr.slice(i, i + 1))
		}
		newStr = t
	}
	let arr = []; //将字符串分隔，两个两个的分隔
	for (var i = 0; i < 6; i = i + 2) {
		let s = newStr.slice(i, i + 2)
		arr.push(parseInt("0x" + s))
	}
	//透明度
	arr.push(opacity);
	return 'rgb(' + arr.join(",") + ')';
}


/**
  * @Description:时间戳转时间
  * @author:Howe
  * @param
  * @return
  * @createTime: 2023-03-03 00:27:57
  * @Copyright by 红逸软件开发工作室
  */
export const timestampToTime = (timestamp : string, type : number = 1) => {
	if (!timestamp) return '';
	// 此处时间戳以毫秒为单位
	let date = new Date(parseInt(timestamp) * 1000);
	let Year = date.getFullYear();
	let Moth = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
	let Day = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
	let Hour = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
	let Minute = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
	let Sechond = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());

	switch (type) {
		case 1:
			return Year;
			break;
		case 2:
			return Year + '-' + Moth;
			break;
		case 3:
			return Year + '-' + Moth + '-' + Day;
			break;
		case 4:
			return Year + '-' + Moth + '-' + Day + ' ' + Hour;
			break;
		case 5:
			return Year + '-' + Moth + '-' + Day + ' ' + Hour + ':' + Minute;
			break;
		case 6:
			return Year + '-' + Moth + '-' + Day + ' ' + Hour + ':' + Minute + ':' + Sechond;
			break;
		default:
	}
}



/**
  * @Description:js小数计算精度丢失问题解决
  * @author:Howe
  * @param
  * @return
  * @createTime: 2023-03-03 00:27:57
  * @Copyright by 红逸软件开发工作室
  */
export const accurateCount = (num1 : number, num2 : number, symbol : string) => {
	let str1 = num1.toString();
	let str2 = num2.toString();
	let result, len1, len2, step;
	try { len1 = str1.split('.')[1].length } catch (e) { len1 = 0 };
	try { len2 = str2.split('.')[1].length } catch (e) { len2 = 0 };
	step = Math.pow(10, Math.max(len1, len2));
	let a = parseInt(num1 * step);
	let b = parseInt(num2 * step);
	switch (symbol) {
		case '+':
			result = (a + b) / step
			break;
		case "-":
			result = (a - b) / step
			break;
		case "*":
			result = (a * b) / step / step
			break;
		case "/":
			result = a / b
			break;
		default:
			break;
	}
	return result;
}