export default [{
	name: '男法官',
	icon: 'nanfaguan'
}, {
	name: '男警察',
	icon: 'nanjingcha'
}, {
	name: '女警察',
	icon: 'nvjingcha'
}, {
	name: '工人',
	icon: 'gongren'
}, {
	name: '女老师',
	icon: 'nvlaoshi'
}, {
	name: '圣诞女郎',
	icon: 'shengdannvlang'
}, {
	name: '爸爸',
	icon: 'baba'
}, {
	name: '奶奶',
	icon: 'nainai'
}, {
	name: '公主',
	icon: 'gongzhu'
}, {
	name: '男孩',
	icon: 'nanhai'
}, {
	name: '男学生',
	icon: 'nanxuesheng'
}, {
	name: '妈妈',
	icon: 'mama'
}, {
	name: '男厨师',
	icon: 'nanchushi'
}, {
	name: '女学生',
	icon: 'nvxuesheng'
}, {
	name: '爷爷',
	icon: 'yeye'
}, {
	name: '新郎',
	icon: 'xinlang'
}, {
	name: '男婴儿',
	icon: 'nanyinger'
}, {
	name: '女医生',
	icon: 'nvyisheng'
}]