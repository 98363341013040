//系统配置
const CONFIG = {
	//开发环境配置
	development: {
		//接口代理地址（前台）
		proxyApi: '/hyApi',
		//接口代理地址（后台）
		proxyHoweApi: '/howeApi',
		//图片代理地址
		proxyImg: '/Howe',
		//域名（图片域名）
		domainName: 'https://api.hyhowe.cn',
		//图片资源
		assetsBase: '/Howe/static/',
		//头像
		avatarBase: '/Howe/static/avatar/',
		//接口地址 
		domainApi: 'https://api.hyhowe.cn',
	//	domainApi: 'http://127.0.0.1:8090',
		//H5地址 
		h5Domain: 'https://mp.hyhowe.cn',
		//APP下载地址
		appDownload: 'https://mp.hyhowe.cn/pages/web/appDownload/appDownload',
		//是否开发人员
		developers: 0,

	},
	//生产环境配置
	production: {
		//接口代理地址（前台）
		proxyApi: '/hyApi',
		//接口代理地址（后台）
		proxyHoweApi: '/howeApi',
		//图片代理地址
		proxyImg: '/Howe',
		//域名（图片域名）
		domainName: 'https://api.hyhowe.cn',
		//图片资源
		assetsBase: '/Howe/static/',
		//头像
		avatarBase: '/Howe/static/avatar/',
		//接口地址 
		domainApi: 'https://api.hyhowe.cn',
		//H5地址
		h5Domain: 'https://mp.hyhowe.cn',
		//APP下载地址
		appDownload: 'https://mp.hyhowe.cn/pages/web/appDownload/appDownload',
		//是否开发人员
		developers: 0,
		
		
		
	}
}

export default {
	//是否开启打印
	isConsoleLog: true,
	//加载前端图片(true)还是服务器图片(false)
	frontEndImg: false,
	//版本  非APP和微信小程序
	version: '1.1.0',
	...CONFIG[process.env.NODE_ENV as any],
	//不需要token（可以使用通配符*   **）
	tokenWhiteList: ['/pages/tabbar/**',
		'/pages/login/authLogin',
		'/pages/login/pwdLogin',
		'/pages/web/webView/webView',
		'/pages/web/error/maintenance',
		'/pages/web/appDownload/appDownload',
		'/pages/my/about/**',
		'/pages/develop/goods/goods',
		'/pages/develop/notice/notice',
		'/pages/develop/notice/edit',
	],

};