export default {
	//微任务类型
	getMicrotaskType: '/hyApi/common/microtask/getType',
	//创建微任务
	microtaskCreate: "/hyApi/server/microtask/create",
	//微任务列表
	microtaskList: '/hyApi/server/microtask/list',
	//微任务详情
	microtaskDetails: '/hyApi/server/microtask/details',

}