import appConfig from '@/core/config/appConfig';

let url : string = appConfig.domainApi;//本系统

const baseURL = () : string => {
	// #ifdef H5
	return '';
	// #endif   

	// #ifndef H5
	return url;
	// #endif    
}

export default baseURL;