export default {
	//文案编辑
	writingEdit: '/hyApi/writing/edit',
	//文案列表
	writingList: '/hyApi/writing/list/index',
	//我的文案
	myWritingList: "/hyApi/writing/my/list",
	//文案详情
	writingDetails: "/hyApi/writing/details",
	//文案删除
	writingDelete: "/hyApi/writing/del",
	//文案收藏
	writingCollect:"/hyApi/writing/collect",
	//文案状态数量
	eachStateNum:"/hyApi/writing/eachStateNum",
	

}