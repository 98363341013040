import baseURL from '@/core/config/baseURLConfig';

const uploadFile = (obj : any) => {
	return new Promise((resolve, reject) => {
		uni.uploadFile({
			url: baseURL() + obj.url,//请求地址
			filePath: obj.data,//图片路径
			name: 'file',
			header: {
				"token": uni.getStorageSync("token")
			},
			timeout: 2000,
			success: (res) => {
				if (JSON.parse(res.data).code == 200) {
					resolve(JSON.parse(res.data))
				}
			},
			fail: (error) => {
				console.log(error); //抛出异常
				reject(error);
			},
			complete: () => {

			}
		})
	})

}




export default uploadFile;