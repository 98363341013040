import $appConfig from '@/core/config/appConfig'; 
/**
  * @Description:  打印日志
  * @author:Howe
  * @param  
  * @return 
  * @createTime: 2022-12-29 14:03:50
  * @Copyright by 红逸
  */
export const log = (Log : String) : void => {
	if ($appConfig.isConsoleLog) {
		console.log(Log);
	}
}

/**
  * @Description: rpx转px
  * @author:Howe
  * @param
  * @return
  * @createTime: 2023-06-04 21:01:10
  * @Copyright by 红逸软件开发工作室
  */
export const rpxToPx = (rpx : number) : number => {
	return uni.upx2px(rpx);
}


/**
  * @Description: px转rpx
  * @author:Howe
  * @param
  * @return
  * @createTime: 2023-06-04 21:01:10
  * @Copyright by 红逸软件开发工作室
  */ 
export const pxToRpx = (px : number) : number => {
	let scale = uni.upx2px(100) / 100;
	return px / scale
}


/**
  * @Description:
  * @author:Howe
  * @param 获取当前页面路径和参数
  * @return
  * @createTime: 2023-01-24 09:49:48
  * @Copyright by 红逸软件开发工作室
  */
export const getFullPath = () => {
	return getCurrentPages()[getCurrentPages().length - 1].$page.fullPath
}
 