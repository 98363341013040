<script setup lang="ts">
	import themeConfig from '@/core/config/themeConfig';
	import usePopupStore from '@/store/modules/popup'
	import useSystemStore from '@/store/modules/system'
	let popupStore = usePopupStore()
	let systemStore = useSystemStore()

	import {
		getCurrentInstance, computed,
		reactive,
		ref,
	} from 'vue';
	import {
		onLaunch,
		onShow,
		onHide
	} from "@dcloudio/uni-app";
	const {
		proxy
	} = getCurrentInstance()
	onLaunch(async () => {
		//uni 全局事件注册 
		await uniGlobalEventMonitor()
		//初始化
		await init();
		//加载完毕
		proxy.$isResolve();
	});
	onShow(() => {



	});
	onHide(() => {

	});
	const uniGlobalEventMonitor = () => {
		//提示信息  toast是对象 
		uni.$on("hy-toast", (toast : any) => {
			popupStore.showToast(toast)
		})
	}


	const init = async () => {
		await proxy.$httpRequest(proxy.$system.getSystemInfo, 'GET').then((res : any) => {
			if (res.data) {
				systemStore.setSystem(res.data.system)
				systemStore.setTabbarList(res.data.tabbar)
				systemStore.setAuth(res.data.limits)
			}
		}).catch(e => {
		}).finally(() => {
		})
	}

	const wxMiniProgramUpdate = () => {
		let updateManager = uni.getUpdateManager();
		if (!updateManager) {
			return;
		} else {
			//新版本更新
			if (uni.canIUse("getUpdateManager")) {
				//判断当前微信版本是否支持版本更新
				updateManager.onCheckForUpdate(function (res) {
					if (res.hasUpdate) {
						// 请求完新版本信息的回调
						updateManager.onUpdateReady(function () {
							uni.showModal({
								title: "更新提示",
								content: "新版本已经准备好，是否重启应用？",
								showCancel: false,
								success: function (res) {
									if (res.confirm) {
										// 新的版本已经下载好，调用 applyUpdate 应用新版本并重启
										updateManager.applyUpdate();
									}
								},
							});
						});
						updateManager.onUpdateFailed(function () {
							uni.showModal({
								// 新的版本下载失败
								title: "已经有新版本了哟~",
								content:
									"新版本已经上线啦~，请您删除当前小程序，到微信 “发现-小程序” 页，重新搜索打开哦~",
							});
						});
					} else {
					}
				});
			} else {
				uni.showModal({
					// 如果希望用户在最新版本的客户端上体验您的小程序，可以这样子提示
					title: "溫馨提示",
					content:
						"当前微信版本过低，部分功能无法使用，请升级到最新微信版本后重试。",
				});
			}
		}
	}
</script>
<style lang="scss">
	@import "core/css/flex.scss";

	@import "core/css/fontIcon.css";
	@import "core/css/common.scss";
	@import "core/css/loadAnimation.scss";
	@import "core/css/ali.font.css";

	.body-content-padding {
		padding: 20rpx 30rpx;
		width: 100%;
	}

	page {
		background-color: #f9f9f9;
	}


</style>