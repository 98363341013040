import baseURL from '@/core/config/baseURLConfig';
import httpError from '@/core/config/errorCodeConfig';
import appConfig from '@/core/config/appConfig';

import useUserStore from '@/store/modules/user'

/**
  * @Description:全局请求封装
  * @author:Howe
  * @param  path  string  地址
  * @param  data   Object  参数
  * @param  method string  请求方式  
  * @param  config   Object  配置信息
  * @param contenType Boolen  请求格式   json
  * @return
  * @createTime: 2023-02-26 22:13:52
  * @Copyright by 红逸软件开发工作室
  */
const httpRequest = (path : string, method : any = 'GET', data = {}, config = {}, contenType = true) => {
	const info = uni.getSystemInfoSync();
	const userStore = useUserStore();
	return new Promise((resolve, reject) => {
		uni.request({
			header: {
				"x-developers": appConfig.developers,
				"x-device-id": info.deviceId || '',
				"x-platform": info.uniPlatform || '',
				"x-hyhowe-key": userStore.hyToken || '',
				"x-admin-hyhowe-key": userStore.hyAdminToken || '',
				'content-type': contenType ? 'application/json' : 'application/x-www-form-urlencoded',
				...config
			},
			url: baseURL() + path,
			method: method,
			data,
			timeout: 20000,
			success(response : any) {
				//网络请求处理 
				if (response.statusCode !== 200) {
					httpError(response);
					return reject();
				} else {
					//错误抛出
					if (response.data.code !== 1000) {
						//系统维护--开启
						if (response.data.code === 4500) {
							uni.reLaunch({
								url: "/pages/web/error/maintenance"
							})
							return;
						}
						//系统维护--未开启
						if (response.data.code === 4501) {
							uni.reLaunch({
								url: "/pages/tabbar/my/my"
							})
							return;
						}
						//-------------------------------------------------//
						uni.$emit("hy-toast", {
							text: response.data.msg,
							type: 'error'
						})
						// 令牌失效
						if (response.data.code === 4030) {
							userStore.loginFailure()
							return;
						}
						// 账号异常
						if (response.data.code === 4031) {
							userStore.logout()
							return;
						}
						//管理令牌失效
						if (response.data.code === 5000) {
							userStore.manageLogout();
							return;
						}
						return reject(response.data);
					}
					resolve(response.data);
				}
			},
			fail(err : any) {
				uni.$emit("hy-toast", {
					text: '网络开小差了~',
					type: 'error'
				})
				console.error(err);
				reject(err);
			}
		});
	});
};

export default httpRequest;