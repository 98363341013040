export default {
	//商品列表
	appGoodsList: '/howeApi/appDevelop/goods/list',
	//商品状态修改
	appGoodsUpdateStatus: '/howeApi/appDevelop/goods/statusUpdate',
	//商品创建、修改
	appGoodsEdit: '/howeApi/appDevelop/goods/edit',
	//商品详情
	appGoodsDetails: '/howeApi/appDevelop/goods/details',
	//订单列表
	orderList: '/howeApi/appDevelop/order/list',
	//订单详情
	appOrderDetails: '/howeApi/appDevelop/order/details',
	//是否接单
	orderTaking: '/howeApi/appDevelop/order/taking',
	//订单完成
	orderComplete: '/howeApi/appDevelop/order/complete',
	//售后订单
	refundOrderList: '/howeApi/appDevelop/refundOrder/list',
	//售后订单详情
	refundDetails: '/howeApi/appDevelop/refundOrder/details',
	//售后是否同意退款
	refundReview: '/howeApi/appDevelop/order/refund/review',
	//软件统计
	appStatistics:"/howeApi/appDevelop/statistics",

}