export default {
	//系统配置信息
	getSystemInfo: '/hyApi/system/getInfo',
	//联系方式
	contactUs: "/hyApi/system/contactUs",
	//公告首页
	noticeIndex: "/hyApi/system/notice/index",
	//公告列表
	noticeList: "/hyApi/system/notice/list",
	//公告详情
	noticeDetails: "/hyApi/system/notice/details",
	//维护信息
	maintenance: "/hyApi/system/maintenance/details",
	//APP更新版本
	appVersionUpdate: "/hyApi/system/version/appUpdate",


}