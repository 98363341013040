import {
	ref
} from 'vue'

/**
  * @Description:防抖  在事件被触发n秒后再执行回调，如果在这n秒内又被触发，则重新计时
  * 频繁触发事件，搜索框输入值，监听浏览器窗口resize,表单提交按钮，登录发短信等等
  * @author:Howe
  * @param  fn  --函数
  * @param  delay  --延迟时间
  * @return
  * @createTime: 2022-12-31 01:05:18
  * @Copyright by 红逸软件开发工作室
  */

export const debounce = (fn : () => void, delay : number | undefined = 500) => {
	let timer : any;
	return (...args : any) => {
		const _this = this;
		if (timer) {
			clearTimeout(timer)
		}
		timer = setTimeout(() => {
			fn.apply(_this, args)
		}, delay)
	}
};
/**
  * @Description:节流   单位时间内，只能触发一次函数
  * 频繁触发事件，onscroll滚动，mousemove 等等
  * @author:Howe
  * @param  fn  --函数
  * @param  delay  --延迟时间
  * @return
  * @createTime: 2022-12-31 01:08:02
  * @Copyright by 红逸软件开发工作室
  */
export const throttle = (fn : () => void, delay : number | undefined = 500) => {
	const isThtottle = ref(true)
	return (...args : any) => {
		const _this = this;
		if (!isThtottle.value) return
		isThtottle.value = false
		setTimeout(() => {
			fn.apply(_this, args)
			isThtottle.value = true
		}, delay)
	}
};