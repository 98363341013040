export default [{
	name: '管理系统验证',
	path: '/pages/system/verify/verify',
	verify: false
}, {
	name: '首页',
	path: '/pages/system/index',
	verify: true
}, {
	name: '用户列表',
	path: '/pages/system/userManage/list/list',
	verify: true
}, {
	name: '用户编辑',
	path: '/pages/system/userManage/userEdit/userEdit',
	verify: true
}, {
	name: '用户数据统计',
	path: '/pages/system/userManage/userStatistics/userStatistics',
	verify: true
}, {
	name: '文案管理',
	path: '/pages/system/writing/manage/manage',
	verify: true
}, {
	name: '文案审核',
	path: '/pages/system/writing/audit/audit',
	verify: true
},{
	name: 'APP商品列表',
	path: '/pages/system/appDevelop/goodsList/goodsList',
	verify: true
}, {
	name: 'APP商品编辑',
	path: '/pages/system/appDevelop/goodsList/create',
	verify: true
},{
	name: 'APP订单',
	path: '/pages/system/appDevelop/order/order',
	verify: true
},{
	name: 'APP订单售后',
	path: '/pages/system/appDevelop/refund/refund',
	verify: true
},{
	name: 'APP订单详情',
	path: '/pages/system/appDevelop/orderDetails/orderDetails',
	verify: true
},{
	name: 'APP售后详情',
	path: '/pages/system/appDevelop/refundDetails/refundDetails',
	verify: true
},{
	name: 'APP订单统计',
	path: '/pages/system/appDevelop/orderStatistics/orderStatistics',
	verify: true
},{
	name: '去水印记录',
	path: '/pages/system/watermark/record/record',
	verify: true
},{
	name: '投票需求',
	path: '/pages/system/serve/vote/vote',
	verify: true
},{
	name: '投票需求详情',
	path: '/pages/system/serve/vote/details',
	verify: true
},{
	name: '系统配置',
	path: '/pages/system/settings/config/index',
	verify: true
},{
	name: '功能提示',
	path: '/pages/system/settings/config/functionTip/functionTip',
	verify: true
},{
	name: 'tabbar配置',
	path: '/pages/system/settings/config/tabbar/tabbar',
	verify: true
},{
	name: '加载动画',
	path: '/pages/system/settings/config/loading/loading',
	verify: true
},{
	name: '系统异常',
	path: '/pages/system/settings/config/systemError/systemError',
	verify: true
},{
	name: '皮肤配置',
	path: '/pages/system/settings/config/skin/skin',
	verify: true
},{
	name: '意见反馈 ',
	path: '/pages/system/settings/feedback/feedback',
	verify: true
},{
	name: '联系我们',
	path: '/pages/system/settings/contactUs/contactUs',
	verify: true
},{
	name: '权限管理',
	path: '/pages/system/settings/auth/auth',
	verify: true
},{
	name: '日志管理',
	path: '/pages/system/settings/log/index',
	verify: true
},{
	name: '操作日志',
	path: '/pages/system/settings/log/operateLog/operateLog',
	verify: true
},{
	name: '登录日志',
	path: '/pages/system/settings/log/loginLog/loginLog',
	verify: true
},{
	name: '用户日志',
	path: '/pages/system/settings/log/userLog/userLog',
	verify: true
},{
	name: '公告列表',
	path: '/pages/system/settings/notice/notice',
	verify: true
},{
	name: '公告详情',
	path: '/pages/system/settings/notice/edit',
	verify: true
}]