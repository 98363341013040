import { defineStore } from 'pinia'


import tabbarList from '@/core/config/tabbarConfig';
import themeConfig from '@/core/config/themeConfig';



const useSystemStore = defineStore('SystemStore', {
	state: () => {
		return {
			tabbarList: tabbarList,
			system: {//系统配置 
				load_page: 'ball-scale-multiple',
			},
			auth: [],//页面权限
			theme: {}, //皮肤
			analysisData: '',

		}
	},
	actions: {
		setTabbarTheme() {
			this.tabbarList.forEach((item : any) => {
				item.selColor = this.theme.hyBaseColor
			})
		},
		setTabbarList(tabbar : any) {
			this.tabbarList = tabbar;
		},
		setSystem(system : any) {
			this.theme = themeConfig[system.theme].colors;
			this.system = system;
		},
		setTheme(theme : any) {
			this.theme = theme;
		},
		setAuth(auth : any) {
			this.auth = auth;
		},
		setAnalysisData(res : any) {
			this.analysisData = res;
		}
	},
	getters: {


	},
	persist: {
		key: 'system',
		storage: {
			getItem(key) {
				return uni.getStorageSync(key)
			},
			setItem(key, value) {
				return uni.setStorageSync(key, value)
			},
		},
	},
})

export default useSystemStore