import App from './App'


import Vuex from "vuex";

//引入pinia  仓库
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'  //持久化
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

//引入拦截器
import './core/routeInterceptor';
//导入的core核心插件
import core from "./core";

import { createSSRApp } from 'vue'

let app3 : any = createSSRApp(App)


app3.use(core);
app3.use(pinia);

export function createApp() {
	return {
		app: app3,
		Vuex
	}
}


//引入vconsole  插件
// #ifdef H5 
import VConsole from 'vconsole'
if (process.env.NODE_ENV === 'development') {
	const vConsole = new VConsole();
}
// #endif